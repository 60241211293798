import React from 'react'
import {
  Button,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import {
  IoTrashBinOutline
} from 'react-icons/io5'
import { GuestMiddleware } from 'utilities/GuestMiddleware'

const DeleteButton = ({ onClick, tooltipText, tooltipPlacement, noMargin, className }) => {
  return (
    <GuestMiddleware>
      <OverlayTrigger
        placement={tooltipPlacement}
        overlay={
          <Tooltip>
            {tooltipText ? tooltipText : 'Hapus data'}
          </Tooltip>
        }
      >
        <Button
          variant="danger"
          size="sm"
          className={`${noMargin ? "m-0" : "m-1"} ${className}`}
          onClick={onClick}
        >
          <IoTrashBinOutline />
        </Button>
      </OverlayTrigger>
    </GuestMiddleware>
  )
}

export default DeleteButton
