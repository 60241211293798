/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { StockFisikApi } from "api"
import {
  ActionButton,
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  UpdateButton
} from "components"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { DateConvert, TableNumber } from "utilities"
import { ModalFilter } from "./Section"
import { ButtonGroup } from "react-bootstrap"

const ListStockFisik = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataSuratJalan, setDataSuratJalan] = useState([])
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.surat_jalan?.filter?.active,
      tgl_stock_fisik_master_mulai:
        location?.state?.surat_jalan?.filter?.tgl_stock_fisik_master_mulai,
      tgl_stock_fisik_master_selesai:
        location?.state?.surat_jalan?.filter?.tgl_stock_fisik_master_selesai,
      id_unit_produksi: location?.state?.surat_jalan?.filter?.id_unit_produksi,
      id_gudang: location?.state?.surat_jalan?.filter?.id_gudang,
      is_opname: location?.state?.surat_jalan?.filter?.is_opname,
      id_item_barang: location?.state?.surat_jalan?.filter?.id_item_barang
    },
    pagination: {
      page: location?.state?.surat_jalan?.filter?.page ?? "1",
      dataLength: location?.state?.surat_jalan?.filter?.dataLength ?? "10",
      totalPage: location?.state?.surat_jalan?.filter?.totalPage ?? "1",
      dataCount: location?.state?.surat_jalan?.filter?.dataCount ?? "0",
    },
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  //menampilkan alert
  const [showAlert, setShowAlert] = useState(false)
  const [searchTime, setSearchTime] = useState(null)

  const getInitialData = () => {
    setIsPageLoading(true)

    StockFisikApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_stock_fisik_master_mulai:
        dataFilter?.filter?.tgl_stock_fisik_master_mulai,
      tgl_stock_fisik_master_selesai:
        dataFilter?.filter?.tgl_stock_fisik_master_selesai,
      id_unit_produksi: dataFilter?.filter?.id_unit_produksi,
      id_gudang: dataFilter?.filter?.id_gudang,
      is_opname: dataFilter?.filter?.is_opname,
      id_item_barang: dataFilter?.filter?.id_item_barang
    })
      .then((data) => {
        setDataSuratJalan(data?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const onInputSearch = (text) => {
    const key = text.target.value
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: key,
        })
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        })
        setShowAlert(true)
      }, 750)
    )
  }

  useEffect(() => {
    setNavbarTitle("Stock Fisik")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_stock_fisik_master_mulai,
    dataFilter?.filter?.tgl_stock_fisik_master_selesai,
    dataFilter?.filter?.id_unit_produksi,
    dataFilter?.filter?.id_gudang,
    dataFilter?.filter?.is_opname,
    dataFilter?.filter?.status_approval,
    dataFilter?.filter?.id_item_barang
  ])

  const PageContent = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : ""
      const convert = data.substring(0, 3)

      if (convert === "APP")
        return {
          variant: "outline-success",
          label: "APPROVED",
        }
      // return "APPROVED";
      if (convert === "VER")
        return {
          variant: "outline-verified",
          label: "VERIFIED",
        }
      // return "VERIFIED";
      if (convert === "REV")
        return {
          variant: "outline-revised",
          label: "REVISION",
        }
      // return "REVISI";
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECTED",
        }
      // return "REJECT";

      return {
        variant: "outline-secondary",
        label: "PENDING",
      }
      // return "PENDING";
    }

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Stock Fisik</b>
        </span>
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Stock Fisik</ThFixed>
              <Th style={{ minWidth: "200px" }}>Unit Produksi</Th>
              <Th style={{ minWidth: "200px" }}>Gudang</Th>
              <ThFixed>Status Stock Opname</ThFixed>
            </tr>
          </thead>
          <tbody>
            {dataSuratJalan.map((val, index) => (
              <tr key={index}>
                <TdFixed>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <Td>
                  <ButtonGroup>
                    <ReadButton
                      noMargin
                      onClick={() =>
                        history.push(
                          "/inventory/transaksi/stock-fisik/detail/" +
                          val.id_stock_fisik_master,
                          {
                            ...location?.state,
                            surat_jalan: dataFilter,
                            action: "READ",
                          }
                        )
                      }
                    />
                    {!val.is_opname && (
                      <UpdateButton
                      noMargin
                        onClick={() =>
                          history.push(
                            "/inventory/transaksi/stock-fisik/ubah/" +
                            val.id_stock_fisik_master,
                            {
                              ...location?.state,
                              surat_jalan: dataFilter,
                              action: "UPDATE",
                            }
                          )
                        }
                      />
                    )}
                  </ButtonGroup>
                </Td>
                <TdFixed>
                  <div className="text-left">
                    {val.tgl_stock_fisik_master
                      ? DateConvert(new Date(val.tgl_stock_fisik_master))
                        .defaultDMY
                      : "-"}
                  </div>
                  <div className="text-left">
                    {val.no_stock_fisik_master ?? "-"}
                  </div>
                </TdFixed>
                <Td>{val.nama_unit_produksi ?? "-"}</Td>
                <Td>{val.nama_gudang ?? "-"}</Td>
                <TdFixed>
                  <ActionButton
                    disable
                    variant={val.is_opname ? "outline-success" : "outline-dark"}
                    text={val.is_opname ? "SUDAH DIOPNAME" : "BELUM DIOPNAME"}
                    size="sm"
                    className="text-nowrap"
                  />
                </TdFixed>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    )

    if (!dataSuratJalan || dataSuratJalan.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
              dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
                dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/inventory/transaksi/stock-fisik/tambah", {
                ...location?.state,
                surat_jalan: dataFilter,
                action: "ADD",
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default ListStockFisik
