/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React
import { RegItemBahanApi } from "api"
import {
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  DeleteModal,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  Switch,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
} from "components"
import { useEffect, useState } from "react"
import { ButtonGroup } from "react-bootstrap"
import { useHistory, useLocation } from "react-router-dom"
import { PageNumber, TableNumber } from "utilities"
import { ModalFilter } from "./components"

const ListItemBarang = ({ setNavbarTitle }) => {
  // Hooks
  const location = useLocation();
  const history = useHistory();

  // Variables
  const title = "Item Barang";
  const filter = location.state?.filter ?? {};

  // States
  const [page, setPage] = useState({
    loading: true,
    status: true,
  });
  const [data, setData] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [isDeleteData, setIsDeleteData] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: filter.active,
    page: filter.page ?? 1,
    per_page: filter.per_page ?? 10,
    q: filter.q ?? "",
    id_kelompok: filter.id_kelompok,
    id_jenis: filter.id_jenis,
    id_pabrikan: filter.id_pabrikan,
    id_satuan_pakai: filter.id_satuan_pakai,
    id_satuan_beli: filter.id_satuan_beli,
    id_satuan_jual: filter.id_satuan_jual,
  });

  const [showAlert, setShowAlert] = useState(false)
  const [searchTime, setSearchTime] = useState(null)

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setDataFilter({
          ...dataFilter,
          page: 1,
          q: key,
      })
      setAlertConfig({
        variant: "primary",
        text: `hasil pencarian : ${key}`,
      });
      setShowAlert(true);
      }, 750)
    );
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const checkSearch = () => {
    if (dataFilter.q !== "") {
      setAlertConfig({
        variant: "primary",
        text: `Hasil Pencarian : ${dataFilter.q}`,
      });
    }
  };

  useEffect(() => {
    setNavbarTitle(title);
    checkAlert();
  }, [dataFilter]);

  useEffect(() => {
    setNavbarTitle(title);
    getData();
  }, [dataFilter]);

  const getData = () => {
    const filterData = { ...dataFilter };
    delete filterData.active;

    setPage({
      loading: true,
      status: false,
    });

    RegItemBahanApi.page(filterData)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);

        setPage({
          loading: false,
          status: true,
        });
      })
      .catch(() => {
        setData([]);
        setPage({
          loading: false,
          status: false,
        });
      })
      .finally(() => checkSearch);
  };

  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_item_buaso: deleteData.id_item_buaso };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      RegItemBahanApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
            show: true,
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal!(${err.response.data.message})`,
            show: true,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Bahan : {deleteData.kode_item}</div>
        <div>Nama Item Bahan : {deleteData.nama_item}</div>
      </DeleteModal>
    );
  };

  const changeDataStatus = (status, id) => {
    setAlertConfig({
      ...alertConfig,
      show: false,
    });

    const value = {
      id_item_buaso: id,
    };

    const onLoadedSuccess = () => {
      setAlertConfig({
        show: true,
        variant: "primary",
        text: "Ubah status data berhasil",
      });
    };

    const onLoadedFailed = () => {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Ubah status data gagal",
      });
    };

    status === true
      ? RegItemBahanApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : RegItemBahanApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Kode Barang</ThFixed>
              <Th style={{ minWidth: "200px" }}>Nama Barang</Th>
              <Th style={{ minWidth: "200px" }}>Kelompok Barang</Th>
              <Th style={{ minWidth: "200px" }}>Jenis Barang</Th>
              <ThFixed>Satuan Beli</ThFixed>
              <ThFixed>Satuan Pakai</ThFixed>
              <ThFixed>Satuan Jual</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={val.id_item_buaso}>
                <TdFixed>{PageNumber(dataFilter.page, dataFilter.per_page, index)}</TdFixed>
                <Td>
                  <div className="d-flex justify-content-center align-items-center">
                  <ButtonGroup>
                    <ReadButton
                      onClick={() =>
                        history.push(
                          `/inventory/transaksi/register-item-bahan/detail/${val.id_item_buaso}`,
                          {
                            filter: dataFilter,
                          }
                        )
                      }
                      noMargin
                    />

                    <UpdateButton
                      onClick={() =>
                        history.push(
                          `/inventory/transaksi/register-item-bahan/ubah/${val.id_item_buaso}`,
                          {
                            filter: dataFilter,
                          }
                        )
                      }
                      noMargin
                    />

                    <DeleteButton
                      onClick={() => {
                        setDeleteData(val);
                        setIsDeleteData(true);
                      }}
                      noMargin
                    />
                  </ButtonGroup>

                    <Switch
                      id={val.id_item_buaso}
                      checked={val.is_hidden ? false : true}
                      onChange={() => changeDataStatus(val.is_hidden, val.id_item_buaso)}
                    />
                    </div>
                </Td>
                <TdFixed>{val.kode_item}</TdFixed>
                <Td>{val.nama_item}</Td>
                <Td>{val.nama_kelompok}</Td>
                <Td>{val.nama_jenis}</Td>
                <Td>{val.kode_satuan_beli}</Td>
                <Td>{val.kode_satuan_pakai}</Td>
                <Td>{val.kode_satuan_jual}</Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((_res, index) => {
                  if (index === data.length - 1) {
                    return TableNumber(dataFilter.page, dataFilter.per_page, index);
                  } else {
                    return null;
                  }
                })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return TableNumber(dataFilter.page, dataFilter.per_page, index);
            } else {
              return null;
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1,
            });
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch}/>

            <FilterButton active={dataFilter?.active} onClick={() => setModalFilter(true)} />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          <CreateButton
            onClick={() =>
              history.push("/inventory/transaksi/register-item-bahan/tambah", {
                filter: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {page.loading || !page.status ? (
        <DataStatus
          loading={page.loading}
          text={page.loading ? "Memuat data..." : "Data gagal dimuat, tidak dapat menampilkan data"}
        />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {isDeleteData && <HapusModal />}
      {modalFilter && (
        <ModalFilter
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default ListItemBarang;
