/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-new-object */
import { MutasiAntarGudangApi } from "api"
import Axios from "axios"
import {
  ActionButton,
  Alert,
  AlertAlreadyStockOpname,
  ApprovalStatusButton,
  CreateButton,
  CRUDLayout,
  DataStatus,
  DatePicker,
  FilterButton,
  Input,
  InputQtyDecimal,
  InputSearch,
  Pagination,
  ReadButton,
  SelectSearch,
  Table,
  Td,
  TdFixed,
  TextArea,
  Th,
  ThFixed,
  UpdateButton,
} from "components"
import { Formik } from "formik"
import JWTDecode from "jwt-decode"
import { useEffect, useState } from "react"
import { ButtonGroup, Card, Col, Modal, Row } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import { DateConvert, DecimalConvert, TableNumber } from "utilities"
import * as Yup from "yup"
import { getStatusApprovalLabel } from "../PenerimaanBarang/functions"
import { ModalFilter } from "./Section"

const MutasiAntarGudang = ({ setNavbarTitle }) => {
  const IdUserAkses = JWTDecode(sessionStorage.getItem("token")).id
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataPenerimaanSuratJalan, setDataPenerimaanSuratJalan] = useState([])
  const [processedData, setProcessedData] = useState({})
  const [showModalFilter, setShowModalFilter] = useState(false)

  const [modalConfig, setModalConfig] = useState({
    type: "",
    show: false,
  })

  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.mag?.filter?.active,
      tgl_mutasi_mulai: location?.state?.mag?.filter?.tgl_mutasi_mulai,
      tgl_mutasi_selesai: location?.state?.mag?.filter?.tgl_mutasi_selesai,
      item_barang: location?.state?.mag?.filter?.item_barang,
      gudang_asal: location?.state?.mag?.filter?.gudang_asal,
      gudang_tujuan: location?.state?.mag?.filter?.gudang_tujuan,
      satuan: location?.state?.mag?.filter?.satuan,
      status_approval: location?.state?.mag?.filter?.status_approval,
    },
    pagination: {
      page: location?.state?.mag?.filter?.page ?? "1",
      dataLength: location?.state?.mag?.filter?.dataLength ?? "10",
      totalPage: location?.state?.mag?.filter?.totalPage ?? "1",
      dataCount: location?.state?.mag?.filter?.dataCount ?? "0",
    },
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  //menampilkan modal
  const [showAlert, setShowAlert] = useState(false)
  const [searchTime, setSearchTime] = useState(null)

  const getInitialData = () => {
    setIsPageLoading(true)

    MutasiAntarGudangApi.getPage({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_mutasi_mulai: dataFilter?.filter?.tgl_mutasi_mulai,
      tgl_mutasi_selesai: dataFilter?.filter?.tgl_mutasi_selesai,
      item_barang: dataFilter?.filter?.item_barang,
      gudang_asal: dataFilter?.filter?.gudang_asal,
      gudang_tujuan: dataFilter?.filter?.gudang_tujuan,
      satuan: dataFilter?.filter?.satuan,
      status_approval: dataFilter?.filter?.status_approval,
    })
      .then((data) => {
        setDataPenerimaanSuratJalan(data?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }
  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }
  const onInputSearch = (text) => {
    const key = text.target.value
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: key,
        })
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        })
        setShowAlert(true)
      }, 750)
    )
  }

  useEffect(() => {
    setNavbarTitle("Mutasi Antar Gudang")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_mutasi_mulai,
    dataFilter?.filter?.tgl_mutasi_selesai,
    dataFilter?.filter?.item_barang,
    dataFilter?.filter?.gudang_asal,
    dataFilter?.filter?.gudang_tujuan,
    dataFilter?.filter?.satuan,
    dataFilter?.filter?.status_approval,
  ])

  const PageContent = () => {
    const DataTable = () => {
      return (
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Mutasi</ThFixed>
              <Th>Item Bahan</Th>
              <Th width={200}>Gudang Asal</Th>
              <Th width={150}>Gudang Tujuan</Th>
              <Th width={100}>Qty. Mutasi</Th>
              <Th>Satuan</Th>
              <ThFixed>Status Approval</ThFixed>
            </tr>
          </thead>
          <tbody>
            {dataPenerimaanSuratJalan.map((val, index) => (
              <tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <ButtonGroup>
                      <ReadButton
                        noMargin
                        onClick={() => {
                          setProcessedData(val)
                          setModalConfig({
                            show: true,
                            type: "detail",
                          })
                        }}
                      />

                      {(val.status_approval === "REV" && IdUserAkses === val.id_karyawan_pengaju) ? (
                        <UpdateButton
                        noMargin
                          onClick={() => {
                            setProcessedData(val)
                            setModalConfig({
                              show: true,
                              type: "update",
                            })
                          }}
                        />
                      ) : null}
                    </ButtonGroup>
                  </div>

                </TdFixed>
                <TdFixed>
                  <div className="text-left">
                    {val.tgl_mutasi_antar_gudang
                      ? DateConvert(new Date(val.tgl_mutasi_antar_gudang))
                        .defaultDMY
                      : "-"}
                  </div>
                  <div className="text-left">
                    {val.no_mutasi_antar_gudang ?? "-"}
                  </div>
                </TdFixed>
                <Td>{val.nama_item ?? "-"}</Td>
                <Td>{val.nama_gudang_asal ?? "-"}</Td>
                <Td>{val.nama_gudang_tujuan ?? "-"}</Td>
                <Td className="text-right">
                  {DecimalConvert(parseFloat(val.qty_mutasi ?? 0)).getWithComa}
                </Td>
                <Td>{val.nama_satuan ?? "-"}</Td>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={
                      getStatusApprovalLabel(val.status_approval).variant
                    }
                  >
                    {getStatusApprovalLabel(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </tr>
            ))}
          </tbody>
        </Table>
      )
    }

    if (!dataPenerimaanSuratJalan || dataPenerimaanSuratJalan.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
              dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
                dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    )
  }

  const PageModal = () => {

    const [dataBahan, setDataBahan] = useState([])
    const [dataGudang, setDataGudang] = useState([])
    const [modalFetchingStatus, setModalFetchingStatus] = useState({
      loading: false,
      success: true,
    })

    const mappingDropdownGudang = (data) => {
      return data.map(
        (val) => new Object({ value: val.id_gudang, label: val.nama_gudang })
      )
    }
    const mappingDropdownBahan = (data) => {
      return data.map(
        (val) =>
          new Object({
            value: val.id_item_buaso,
            label: val.nama_item,
            kode_item: val.kode_item,
            dimensi: val.dimensi,
            pj: val.pj,
            lb: val.lb,
            tb: val.tb,
            satuan_pakai: val.satuan_pakai,
            qty_diajukan: val.qty_diajukan,
          })
      )
    }

    const getInitialModalData = () => {
      setModalFetchingStatus({
        loading: true,
        success: false,
      })

      Axios.all([
        MutasiAntarGudangApi.getDropdown({ tipe: "bahan" }),
        MutasiAntarGudangApi.getDropdown({ tipe: "gudang" }),
      ])
        .then(
          Axios.spread((bahan, gudang) => {
            const mapDropdownBahan = mappingDropdownBahan(bahan.data.data)
            const mapDropdownGudang = mappingDropdownGudang(gudang.data.data)

            setDataBahan(mapDropdownBahan)
            setDataGudang(mapDropdownGudang)
            setModalFetchingStatus({
              loading: false,
              success: true,
            })
          })
        )
        .catch((err) => {
          setModalFetchingStatus({
            loading: false,
            success: false,
          })
        })
    }

    useEffect(() => {
      getInitialModalData()

      return () => { }
    }, [])

    const ModalContent = () => {
      const formInitialValues = {
        tgl: processedData.tgl_mutasi_antar_gudang ?? undefined,
        no: processedData.no_mutasi_antar_gudang ?? "",
        id_item_buaso: processedData.id_item_buaso ?? "",
        id_gudang_asal: processedData.id_gudang_asal ?? "",
        id_gudang_tujuan: processedData.id_gudang_tujuan ?? "",
        stock_update: 0,
        qty_mutasi: processedData.qty_mutasi,
        catatan_mutasi: processedData.catatan_mutasi ?? "",
        satuan_pakai: processedData.satuan_pakai ?? "",
        qty_diajukan: parseFloat(processedData.qty_diajukan ?? 0),
      }

      const formValidationSchema = Yup.object().shape({
        tgl: Yup.string().required("Pilih tanggal mutasi antar gudang"),
        no: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
        id_item_buaso: Yup.string().required("Pilih item bahan"),
        id_gudang_asal: Yup.string().required("Pilih gudang asal"),
        id_gudang_tujuan: Yup.string().required("Pilih gudang tujuan"),
        qty_mutasi: Yup.string().required("Masukkan qty. mutasi"),
      })

      const formSubmitHandler = (values, { setSubmitting }) => {
        if (
          parseFloat(values.stock_update) > 0 &&
          parseFloat(values.qty_mutasi) <= parseFloat(values.stock_update)
        ) {
          const finalValues = {
            tgl_mutasi_antar_gudang: values.tgl,
            id_item_buaso: values.id_item_buaso,
            id_gudang_asal: values.id_gudang_asal,
            id_gudang_tujuan: values.id_gudang_tujuan,
            qty_mutasi: parseFloat(values.qty_mutasi),
            catatan_mutasi: values.catatan_mutasi,
          }

          if (modalConfig.type === "update") {
            finalValues.id_mutasi_antar_gudang =
              processedData.id_mutasi_antar_gudang
          }

          MutasiAntarGudangApi.save(modalConfig.type, finalValues)
            .then(() => {
              setAlertConfig({
                show: true,
                variant: "primary",
                text: "Data berhasil disimpan!",
              })
            })
            .catch(() => {
              setAlertConfig({
                show: true,
                variant: "danger",
                text: "Data gagal disimpan!",
              })
            })
            .finally(() => {
              setProcessedData({})
              setModalConfig({
                show: false,
                type: "",
              })
              getInitialData()
            })
        } else {
          setSubmitting(false)
          window.alert(
            "Tidak dapat menyimpan data (Stock Update kosong / Qty. Mutasi melebihi Stock Update )"
          )
        }
      }

      const FormSection = ({ formik }) => {
        const [isNomorLoading, setIsNomorLoading] = useState(false)
        const {
          values,
          errors,
          touched,
          setValues,
          handleChange,
          setFieldValue,
        } = formik

        const getNomorHandler = (tanggal) => {
          setIsNomorLoading(true)
          MutasiAntarGudangApi.getNomor({ tanggal })
            .then((val) => {
              setFieldValue("tgl", tanggal)
              setFieldValue("no", val?.data?.data ?? "-")
              setFieldValue("id_gudang_asal", null)
              setFieldValue("id_gudang_tujuan", null)
            })
            .catch(() => {
              window.alert("Gagal memuat nomor")
              setValues({
                tgl: "",
                no: "",
              })
            })
            .finally(() => setIsNomorLoading(false))
        }

        const getStockUpdate = () => {
          MutasiAntarGudangApi.getStockUpdate({
            id_bahan: values.id_item_buaso,
            id_gudang: values.id_gudang_asal,
            tgl: values.tgl,
          })
            .then((res) => {
              setFieldValue("stock_update", res?.data?.data?.jumlah ?? 0)
              setFieldValue("qty_diajukan", res?.data?.data?.qty_diajukan ?? 0)
            })
            .catch((err) => window.alert("Gagal memuat stock update"))
        }

        useEffect(() => {
          if (modalConfig.type === "create") {
            // getNomorHandler(today);
          }
        }, [])

        useEffect(() => {
          //fetch getStockUpdate jika dropdown
          if (values.id_item_buaso !== "" && values.id_gudang_asal !== "") {
            getStockUpdate()
          }
          return () => { }
        }, [values.id_item_buaso, values.id_gudang_asal])

        return (
          <>
            <Row>
              <Col md>
                <DatePicker
                  label="Tgl. Mutasi"
                  selected={values.tgl ? new Date(values.tgl) : ""}
                  placeholderText="Pilih tanggal"
                  onChange={(date) => {
                    if (modalConfig.type === "create") {
                      getNomorHandler(DateConvert(new Date(date)).default)
                    }
                    if (modalConfig.type === "update") {
                      setFieldValue("tgl", DateConvert(new Date(date)).default)
                    }
                  }}
                  error={Boolean(errors.tgl) && touched.tgl}
                  errorText={Boolean(errors.tgl) && touched.tgl && errors.tgl}
                />
              </Col>
              <Col md>
                <Input
                  label="No. Mutasi"
                  placeholder={
                    isNomorLoading
                      ? "Memuat nomor . . ."
                      : "Pilih tanggal untuk menentukan nomor"
                  }
                  value={isNomorLoading ? "Memuat nomor . . ." : values.no}
                  error={Boolean(errors.no) && touched.no}
                  errorText={Boolean(errors.no) && touched.no && errors.no}
                  readOnly
                />
              </Col>
            </Row>
            <SelectSearch
              label="Item Bahan"
              name="id_item_buaso"
              placeholder="Pilih item bahan"
              defaultValue={
                dataBahan.find((e) => e.value === values.id_item_buaso) ?? ""
              }
              onChange={(val) => {
                setFieldValue("id_item_buaso", val.value)
                setFieldValue("satuan_pakai", val.satuan_pakai)
                setFieldValue("qty_diajukan", val.qty_diajukan)
              }}
              option={dataBahan}
              error={Boolean(errors.id_item_buaso) && touched.id_item_buaso}
              errorText={
                Boolean(errors.id_item_buaso) &&
                touched.id_item_buaso &&
                errors.id_item_buaso
              }
            />
            <Row>
              <Col md={6}>
                <SelectSearch
                  key={values?.id_gudang_asal}
                  label="Gudang Asal"
                  name="id_gudang_asal"
                  placeholder="Pilih gudang asal"
                  defaultValue={
                    dataGudang.find((e) => e.value === values.id_gudang_asal) ??
                    ""
                  }
                  onChange={(val) => setFieldValue("id_gudang_asal", val.value)}
                  option={dataGudang}
                  error={
                    Boolean(errors.id_gudang_asal) && touched.id_gudang_asal
                  }
                  errorText={
                    Boolean(errors.id_gudang_asal) &&
                    touched.id_gudang_asal &&
                    errors.id_gudang_asal
                  }
                />
              </Col>
              <Col md={2}>
                <InputQtyDecimal
                  label="Stock Update"
                  name="stock_update"
                  value={parseFloat(values.stock_update ?? 0)}
                  readOnly
                />
              </Col>
              <Col md={2}>
                <InputQtyDecimal
                  label="Qty. Diajukan"
                  name="qty_diajukan"
                  value={parseFloat(values.qty_diajukan ?? 0)}
                  readOnly
                />
              </Col>
              <Col md={2}>
                <Input
                  label="Satuan"
                  name="satuan_pakai"
                  value={values.satuan_pakai}
                  readOnly
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <SelectSearch
                  key={values?.id_gudang_tujuan}
                  label="Gudang Tujuan"
                  name="id_gudang_tujuan"
                  placeholder="Pilih gudang tujuan"
                  defaultValue={
                    dataGudang.find(
                      (e) => e.value === values.id_gudang_tujuan
                    ) ?? ""
                  }
                  onChange={(val) =>
                    setFieldValue("id_gudang_tujuan", val.value)
                  }
                  option={dataGudang}
                  error={
                    Boolean(errors.id_gudang_tujuan) && touched.id_gudang_tujuan
                  }
                  errorText={
                    Boolean(errors.id_gudang_tujuan) &&
                    touched.id_gudang_tujuan &&
                    errors.id_gudang_tujuan
                  }
                />
              </Col>
              <Col md={2}>
                <InputQtyDecimal
                  label="Qty. Sisa"
                  name="qty_sisa"
                  value={
                    parseFloat(values.stock_update ?? 0) -
                    parseFloat(values.qty_diajukan ?? 0)
                  }
                  readOnly
                />
              </Col>
              <Col md={2}>
                <InputQtyDecimal
                  label="Qty. Mutasi"
                  id="qty_mutasi"
                  name="qty_mutasi"
                  value={values.qty_mutasi}
                  onChange={(val) => {
                    const sisa =
                      parseFloat(values.stock_update ?? 0) -
                      parseFloat(values.qty_diajukan ?? 0)

                    const value = val > sisa ? 0 : val
                    setFieldValue("qty_mutasi", value)
                  }}
                  error={errors.qty_mutasi && touched.qty_mutasi && true}
                  errorText={errors.qty_mutasi}
                />
              </Col>
              <Col md={2}>
                <Input
                  label="Satuan"
                  name="satuan_pakai"
                  value={values.satuan_pakai}
                  readOnly
                />
              </Col>
            </Row>
            <TextArea
              label="Catatan Mutasi"
              type="text"
              name="catatan_mutasi"
              value={values.catatan_mutasi}
              onChange={handleChange}
              rows={2}
              error={errors.catatan_mutasi && touched.catatan_mutasi && true}
              errorText={errors.catatan_mutasi}
            />
          </>
        )
      }

      // CONTENT KETIKA MELIHAT DETAIL
      const FormDetail = () => {
        const InfoItem = ({ title, text, line }) => (
          <div className="mb-2">
            <small
              className="text-secondary"
              style={{ opacity: 0.8, textTransform: "capitalize" }}
            >
              {title}
            </small>
            <div
              className="text-dark"
              style={{
                fontWeight: 500,
              }}
            >
              {text ?? "-"}
            </div>
            {line && <hr className="my-2" />}
          </div>
        )

        const CatatanApprovalCard = () => {
          const InfoItem = ({ title1, value1, title2, value2 }) => (
            <div className="mb-2">
              <small>{title1}</small>
              <br />
              <b>{value1}</b>
              <div className="mt-1" />
              <small>{title2}</small>
              <br />
              <b>{value2}</b>
            </div>
          )

          return (
            <Card className="mt-4 mb-5">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <b>Catatan Approval Mutasi Antar Gudang</b>
              </Card.Header>
              <Card.Body>
                {processedData.stakeholder ? (
                  <Row>
                    {processedData.stakeholder.map((val, index) => (
                      <Col sm>
                        <InfoItem
                          title1={
                            val.status_approval === "VER" ||
                              val.status_approval === "REV"
                              ? `Pemeriksa ${val.approval_level !== "0"
                                ? val.approval_level
                                : ""
                              }`
                              : val.status_approval === "APP"
                                ? "Pengesah"
                                : val.status_approval === "PEN"
                                  ? "Pembuat"
                                  : "Di Tolak Oleh"
                          }
                          value1={val.nama_karyawan ?? "-"}
                          title2="Catatan"
                          value2={val.catatan ?? "-"}
                        />
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <div className="d-flex justify-content-center">
                    <DataStatus text="Tidak ada catatan" />
                  </div>
                )}
              </Card.Body>
            </Card>
          )
        }

        return (
          <Col>
            <InfoItem
              title="Tgl. Mutasi"
              text={
                DateConvert(new Date(processedData.tgl_mutasi_antar_gudang))
                  .detail
              }
            />
            <InfoItem
              title="No. Mutasi"
              text={processedData.no_mutasi_antar_gudang}
            />
            <InfoItem title="Item Bahan" text={processedData.nama_item} />
            <InfoItem
              title="Gudang Asal"
              text={processedData.nama_gudang_asal}
            />
            <InfoItem
              title="Gudang Tujuan"
              text={processedData.nama_gudang_tujuan}
            />
            <InfoItem
              title="Qty. Mutasi"
              text={parseFloat(processedData.qty_mutasi ?? 0)
                .toString()
                .replace(".", ",")}
            />
            <InfoItem
              title="Catatan Mutasi"
              text={processedData.catatan_mutasi}
            />
            <CatatanApprovalCard />
          </Col>
        )
      }

      return (
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Modal.Body>
                {modalConfig.type === "detail" ? (
                  <FormDetail />
                ) : (
                  <>
                    <FormSection formik={formik} />
                    <AlertAlreadyStockOpname tanggal={formik?.values?.tgl} />
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                {modalConfig.type !== "detail" && (
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Simpan"
                    loading={formik.isSubmitting}
                  />
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      )
    }

    return (
      <Modal
        size="lg"
        show={modalConfig.show}
        onHide={() => {
          setProcessedData({})
          setModalConfig({
            show: false,
            type: "",
          })
        }}
      >
        <Modal.Header closeButton>
          <b className="text-capitalize">
            {`${modalConfig.type === "create"
                ? "Tambah"
                : modalConfig.type === "update"
                  ? "Ubah"
                  : "Detail"
              } Data Mutasi Antar Gudang`}
          </b>
        </Modal.Header>
        {modalFetchingStatus.loading ? (
          <DataStatus loading text="Memuat data . . ." />
        ) : modalFetchingStatus.success ? (
          <ModalContent />
        ) : (
          <DataStatus text="Data gagal dimuat" />
        )}
      </Modal>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            size="md"
            className="col"
            onClick={() => setModalConfig({ show: true, type: "create" })}
          // onClick={() => history.push("/inventory/transaksi/mutasi-antar-gudang/approval")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {/* Modal */}
      {modalConfig.show && <PageModal />}
    </CRUDLayout>
  )
}

export default MutasiAntarGudang
