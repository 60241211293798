/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React
import { useEffect, useState } from "react"

// Component
import { ButtonGroup, Modal } from "react-bootstrap"
import {
  ActionButton,
  Alert,
  CreateButton,
  CreateModal,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  DeleteModal,
  Input,
  InputSearch,
  ListData,
  Pagination,
  ReadButton,
  ReadModal,
  SelectSearch,
  Switch,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
  UpdateModal,
} from "../../../components"
import { HeadOfficeStatus, PageNumber, TableNumber } from "../../../utilities"

// Form
import { Formik } from "formik"
import * as Yup from "yup"

// API
import Axios from "axios"
import { GudangApi } from "../../../api"

const Gudang = ({ setNavbarTitle }) => {
  // VARIABLE
  const title = "Master Gudang"
  const id_unit_produksi = sessionStorage.getItem("id_unit_produksi")

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true)

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false)
  const [searchKey, setSearchKey] = useState("")

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false) // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false) // MODAL UPDATE STATE
  const [isReadForm, setIsReadForm] = useState(false) // MODAL DETAIL STATE
  const [isDeleteData, setIsDeleteData] = useState(false) // MODAL HAPUS STATE

  // STATE DATA GUDANG
  const [data, setData] = useState([])
  // const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [dataLength, setDataLength] = useState(10)
  const [totalData, setTotalData] = useState("")
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([])
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([])
  // STATE MENAMPUNG DATA KARYAWAN
  const [karyawanData, setKaryawanData] = useState([])
  // STATE MENAMPUNG DATA UNIT PRODUKSI
  const [unitOrganisasiData, setUnitOrganisasiData] = useState([])
  const [unitProduksiData, setUnitProduksiData] = useState([])
  const [dataFlag, setDataFlag] = useState([
    {
      nama_flag: "Gudang Umum",
      flag: "GU",
    },
    {
      nama_flag: "Gudang Bahan",
      flag: "GB",
    },
    {
      nama_flag: "Gudang Barang Jadi",
      flag: "GBJ",
    },
  ])
  // STATE MENAMPUNG GENERATE KODE ITEM
  const [kodeItem, setKodeItem] = useState([])

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false)
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })

  const [searchTime, setSearchTime] = useState(null)

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    const value = {
      page,
      per_page: dataLength,
      q: searchKey,
    }
    if (!HeadOfficeStatus()) value.id_unit_produksi = id_unit_produksi

    Axios.all([
      GudangApi.page(value),
      GudangApi.getKaryawan(),
      GudangApi.getUnitOrganisasi(),
      GudangApi.generateKodeItem(),
      GudangApi.getUnitProduksi(),
    ])
      .then(
        Axios.spread((res, karyawan, unit_org, kodeItem, unit_produksi) => {
          setData(res.data.data)
          setTotalData(res.data.data_count)
          setKaryawanData(karyawan.data.data)
          setUnitOrganisasiData(unit_org.data.data)
          setKodeItem(kodeItem.data.data)
          setTotalPage(res.data.total_page)
          setUnitProduksiData(unit_produksi.data.data)
        })
      )
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          })
          setShowAlert(true)
        }
        setIsLoading(false)
      })
  }

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true)

    const value = {
      id_gudang: id,
    }

    const onLoadedSuccess = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      })
      setShowAlert(true)
    }

    const onLoadedFailed = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      })
      setShowAlert(true)
    }

    status === true
      ? GudangApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
      : GudangApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
  }

  // FUNCTION CARI DATA DARI SERVER
  const onInputSearch = (text) => {
    const key = text.target.value // SearchKey Value as key
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key)
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        })
        setShowAlert(true)
      }, 750)
    )
  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title) // SET JUDUL NAVBAR
    getData() // GET DATA DARI SERVER

    return () => {
      setIsLoading(false)
      setIsSearching(false)
    }
  }, [setNavbarTitle, page, dataLength, searchKey])

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    kode_gudang: Yup.string().required("Masukkan kode Gudang"),
    nama_gudang: Yup.string().required("Masukkan nama Gudang"),
    alamat_gudang: Yup.string().required("Masukkan Alamat Gudang"),
    // keterangan_gudang: Yup.string().required("Masukkan Keterangan"),
    id_unit_organisasi: Yup.string().required("Pilih Unit Organisasi"),
    id_karyawan: Yup.string().required("Pilih Penanggung Jawab"),
    id_unit_produksi: Yup.string().required("Pilih Unit Produksi"),
    flag: Yup.string().required("Pilih Jenis Gudang"),
  })

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    const [idUnit, setIdUnit] = useState("")
    const [isUnitChange, setIsUnitChange] = useState(true)
    const [penanggungJawab, setPenanggungJawab] = useState([])
    const [karyawanFilterd, setKaryawanFiltered] = useState([])
    const [karyawanLoading, setKaryawanLoading] = useState(false)

    const getPenanggungJawab = () => {
      setKaryawanLoading(true)
      GudangApi.getKaryawan()
        .then((data) => {
          setPenanggungJawab(data.data.data)
        })
        .finally(() => setKaryawanLoading(false))
    }

    const UnitOrganisasiChange = (id_unit) => {
      const karyawanfilter = penanggungJawab.filter((val) => val.id_unit_organisasi === id_unit)
      setKaryawanFiltered(karyawanfilter)
    }

    useEffect(() => {
      getPenanggungJawab()
    }, [])
    // FORMIK INITIAL VALUES
    const formInitialValues = {
      kode_gudang: kodeItem,
      nama_gudang: "",
      alamat_gudang: "",
      keterangan_gudang: "",
      id_unit_organisasi: "",
      id_karyawan: "",
      id_unit_produksi: "",
      flag: "",
    }

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      setIsCreateForm(false)
      GudangApi.create(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          })
        })
        .catch((err) => {
          setShowAlert(true)
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false)
          // TAMPILKAN ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }
    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Kode Gudang"
                  type="text"
                  name="kode_gudang"
                  placeholder="Kode Gudang"
                  value={values.kode_gudang}
                  readOnly={true}
                  onChange={handleChange}
                  error={errors.kode_gudang && touched.kode_gudang && true}
                  errorText={errors.kode_gudang}
                />

                <Input
                  label="Nama Gudang"
                  type="text"
                  name="nama_gudang"
                  placeholder="Nama Gudang"
                  value={values.nama_gudang}
                  onChange={handleChange}
                  error={errors.nama_gudang && touched.nama_gudang && true}
                  errorText={errors.nama_gudang}
                />

                <SelectSearch
                  label="Jenis Gudang"
                  name="flag"
                  placeholder="Pilih Jenis Gudang"
                  onChange={(val) => {
                    setFieldValue("flag", val.value)
                  }}
                  option={dataFlag.map((val) => {
                    return {
                      value: val.flag,
                      label: val.nama_flag,
                    }
                  })}
                  error={errors.flag && touched.flag && true}
                  errorText={errors.flag && touched.flag && errors.flag}
                />

                <Input
                  label="Alamat Gudang"
                  type="text"
                  name="alamat_gudang"
                  placeholder="Alamat Gudang"
                  value={values.alamat_gudang}
                  onChange={handleChange}
                  error={errors.alamat_gudang && touched.alamat_gudang && true}
                  errorText={errors.alamat_gudang}
                />

                <Input
                  label="Keterangan Gudang"
                  type="text"
                  name="keterangan_gudang"
                  placeholder="Keterangan Gudang"
                  value={values.keterangan_gudang}
                  onChange={handleChange}
                  error={errors.keterangan_gudang && touched.keterangan_gudang && true}
                  errorText={errors.keterangan_gudang}
                />

                <SelectSearch
                  label="Unit Organisasi"
                  name="id_unit_organisasi"
                  placeholder="Pilih Unit Organisasi"
                  onChange={(val) => {
                    setFieldValue("id_unit_organisasi", val.value)
                    setIdUnit(val.value)
                    setIsUnitChange(false)
                    UnitOrganisasiChange(val.value)
                    getPenanggungJawab()
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={unitOrganisasiData
                    .filter((val) => val.is_hidden === false)
                    .map((val) => {
                      return {
                        value: val.id_unit_organisasi,
                        label: val.nama_unit_organisasi,
                      }
                    })}
                  error={errors.id_unit_organisasi && touched.id_unit_organisasi && true}
                  errorText={
                    errors.id_unit_organisasi &&
                    touched.id_unit_organisasi &&
                    errors.id_unit_organisasi
                  }
                />
                {karyawanLoading === true ? (
                  <small>Memuat Data....</small>
                ) : (
                  <SelectSearch
                    label="Penanggung Jawab"
                    name="id_karyawan"
                    placeholder={
                      isUnitChange !== false
                        ? "Pilih Unit Organisasi Terlebih Dahulu"
                        : "Pilih Penanggung Jawab"
                    }
                    isDisabled={isUnitChange}
                    onChange={(val) => {
                      setFieldValue("id_karyawan", val.value)
                    }}
                    // MAPPING OPTIONS
                    // OPTIONS BERUPA VALUE DAN LABEL
                    option={karyawanFilterd.map((val) => {
                      return {
                        value: val.id_karyawan,
                        label: val.nama_karyawan,
                      }
                    })}
                    error={errors.id_karyawan && touched.id_karyawan && true}
                    errorText={errors.id_karyawan && touched.id_karyawan && errors.id_karyawan}
                  />
                )}

                <SelectSearch
                  label="Unit Produksi"
                  name="id_unit_produksi"
                  placeholder="Pilih Unit Produksi"
                  onChange={(val) => {
                    setFieldValue("id_unit_produksi", val.value)
                    setIdUnit(val.value)
                    setIsUnitChange(false)
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={unitProduksiData.map((val) => {
                    return {
                      value: val.id_unit_produksi,
                      label: val.nama_unit_produksi,
                    }
                  })}
                  error={errors.id_unit_produksi && touched.id_unit_produksi && true}
                  errorText={
                    errors.id_unit_produksi && touched.id_unit_produksi && errors.id_unit_produksi
                  }
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Tambah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    )
  }

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const [penanggungJawab, setPenanggungJawab] = useState([])
    const [karyawanFilterd, setKaryawanFiltered] = useState([])
    const [karyawanLoading, setKaryawanLoading] = useState(false)

    const getPenanggungJawab = (id_unit) => {
      console.log("test")
      setKaryawanLoading(true)
      GudangApi.getKaryawan()
        .then((data) => {
          const value = data.data.data ?? []
          const karyawanfilter = value.filter((val) => val.id_unit_organisasi === id_unit)

          setPenanggungJawab(karyawanfilter)
        })
        .finally(() => setKaryawanLoading(false))
    }
    console.log(penanggungJawab)

    const formInitialValues = {
      kode_gudang: updateData.kode_gudang,
      nama_gudang: updateData.nama_gudang,
      alamat_gudang: updateData.alamat_gudang,
      keterangan_gudang: updateData.keterangan_gudang,
      id_unit_organisasi: updateData.id_unit_organisasi,
      id_karyawan: updateData.id_karyawan,
      id_unit_produksi: updateData.id_unit_produksi,
      flag: updateData.flag,
    }

    useEffect(() => {
      getPenanggungJawab(updateData.id_unit_organisasi)
      // UnitOrganisasiChange(formInitialValues.id_unit_organisasi);
    }, [])

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_gudang: updateData.id_gudang,
        ...values,
      }
      GudangApi.update(finalValues)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          })
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          })
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false)
          // SHOW ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Kode Gudang"
                  type="text"
                  name="kode_gudang"
                  placeholder="Kode Gudang"
                  value={values.kode_gudang}
                  readOnly={true}
                  onChange={handleChange}
                  error={errors.kode_gudang && touched.kode_gudang && true}
                  errorText={errors.kode_gudang}
                />

                <Input
                  label="Nama Gudang"
                  type="text"
                  name="nama_gudang"
                  placeholder="Nama Gudang"
                  value={values.nama_gudang}
                  onChange={handleChange}
                  error={errors.nama_gudang && touched.nama_gudang && true}
                  errorText={errors.nama_gudang}
                />

                <SelectSearch
                  label="Jenis Gudang"
                  name="flag"
                  placeholder="Pilih Jenis Gudang"
                  onChange={(val) => {
                    setFieldValue("flag", val.value)
                  }}
                  option={dataFlag.map((val) => {
                    return {
                      value: val.flag,
                      label: val.nama_flag,
                    }
                  })}
                  defaultValue={{
                    value: values.flag ?? "",
                    label:
                      values.flag !== ""
                        ? dataFlag.find((e) => e.flag === values.flag)?.nama_flag ??
                        "Pilih Jenis Gudang"
                        : "Pilih Jenis Gudang",
                  }}
                  error={errors.flag && touched.flag && true}
                  errorText={errors.flag && touched.flag && errors.flag}
                />

                <Input
                  label="Alamat Gudang"
                  type="text"
                  name="alamat_gudang"
                  placeholder="Alamat Gudang"
                  value={values.alamat_gudang}
                  onChange={handleChange}
                  error={errors.alamat_gudang && touched.alamat_gudang && true}
                  errorText={errors.alamat_gudang}
                />

                <Input
                  label="Keterangan Gudang"
                  type="text"
                  name="keterangan_gudang"
                  placeholder="Keterangan Gudang"
                  value={values.keterangan_gudang}
                  onChange={handleChange}
                  error={errors.keterangan_gudang && touched.keterangan_gudang && true}
                  errorText={errors.keterangan_gudang}
                />

                <SelectSearch
                  label="Unit Organisasi"
                  name="id_unit_organisasi"
                  placeholder="Pilih Unit Organisasi"
                  onChange={(val) => {
                    setFieldValue("id_unit_organisasi", val.value)
                    // UnitOrganisasiChange();
                    getPenanggungJawab(val.value)
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={unitOrganisasiData
                    .filter((val) => val.is_hidden === false)
                    .map((val) => {
                      return {
                        value: val.id_unit_organisasi,
                        label: val.nama_unit_organisasi,
                      }
                    })}
                  // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                  defaultValue={{
                    value: values.id_unit_organisasi ?? "",
                    label:
                      values.id_unit_organisasi !== ""
                        ? unitOrganisasiData.find(
                          (e) => e.id_unit_organisasi === values.id_unit_organisasi
                        )?.nama_unit_organisasi ?? "Pilih Unit Organisasi"
                        : "Pilih Unit Organisasi",
                  }}
                  error={errors.id_unit_organisasi && touched.id_unit_organisasi && true}
                  errorText={
                    errors.id_unit_organisasi &&
                    touched.id_unit_organisasi &&
                    errors.id_unit_organisasi
                  }
                />

                {karyawanLoading === true ? (
                  <small>Memuat Data....</small>
                ) : (
                  <SelectSearch
                    label="Kepala Penanggung Jawab"
                    name="id_karyawan"
                    placeholder="Pilih Kepala Penanggung Jawab"
                    onChange={(val) => {
                      setFieldValue("id_karyawan", val.value)
                    }}
                    // MAPPING OPTIONS
                    // OPTIONS BERUPA VALUE DAN LABEL
                    option={penanggungJawab.map((val) => {
                      return {
                        value: val.id_karyawan,
                        label: val.nama_karyawan,
                      }
                    })}
                    // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                    defaultValue={{
                      value: values.id_karyawan ?? "",
                      label:
                        values.id_karyawan !== ""
                          ? karyawanData.find((e) => e.id_karyawan === values.id_karyawan)
                            ?.nama_karyawan ?? "Pilih Kepala Unit"
                          : "Pilih Kepala Unit",
                    }}
                    error={errors.id_karyawan && touched.id_karyawan && true}
                    errorText={errors.id_karyawan && touched.id_karyawan && errors.id_karyawan}
                  />
                )}

                <SelectSearch
                  label="Unit Produksi"
                  name="id_unit_produksi"
                  placeholder="Pilih Unit Produksi"
                  onChange={(val) => {
                    setFieldValue("id_unit_produksi", val.value)
                    // setIdUnit(val.value)
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={unitProduksiData.map((val) => {
                    return {
                      value: val.id_unit_produksi,
                      label: val.nama_unit_produksi,
                    }
                  })}
                  // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                  defaultValue={{
                    value: values.id_unit_produksi ?? "",
                    label:
                      values.id_unit_produksi !== ""
                        ? unitProduksiData.find(
                          (e) => e.id_unit_produksi === values.id_unit_produksi
                        )?.nama_unit_produksi ?? "Pilih Unit Produksi"
                        : "Pilih Unit Produksi",
                  }}
                  error={errors.id_unit_produksi && touched.id_unit_produksi && true}
                  errorText={
                    errors.id_unit_produksi && touched.id_unit_produksi && errors.id_unit_produksi
                  }
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    )
  }

  // MODAL DETAIL COMPONENT
  const DetailModal = () => (
    <ReadModal show={isReadForm} onHide={() => setIsReadForm(false)} title={title}>
      <Modal.Body>
        <ListData label="Kode Gudang">{updateData.kode_gudang ?? "-"}</ListData>
        <ListData label="Nama Gudang">{updateData.nama_gudang ?? "-"}</ListData>
        <ListData label="Jenis Gudang">
          {updateData.flag !== ""
            ? dataFlag.find((val) => val.flag === updateData.flag).nama_flag
            : "-"}
        </ListData>
        <ListData label="Alamat Gudang">{updateData.alamat_gudang ?? "-"}</ListData>
        <ListData label="Keterangan Gudang">{updateData.keterangan_gudang ?? "-"}</ListData>
        <ListData label="Unit Organisasi">{updateData.nama_unit_organisasi ?? "-"}</ListData>
        <ListData label="Kepala Penanggung Jawab">{updateData.nama_karyawan ?? "-"}</ListData>
        <ListData label="Unit Produksi">{updateData.nama_unit_produksi ?? "-"}</ListData>
      </Modal.Body>
    </ReadModal>
  )

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_gudang: deleteData.id_gudang }

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false)

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true)
      GudangApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false)
          // TAMPIL ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Gudang : {deleteData.kode_gudang}</div>
        <div>Nama Gudang : {deleteData.nama_gudang}</div>
      </DeleteModal>
    )
  }

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Kode Gudang</ThFixed>
              <Th>Nama Gudang</Th>
              <Th>Unit Organisasi</Th>
              <Th>Penanggung Jawab</Th>
              <Th>Unit Produksi</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={index}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ButtonGroup>
                        <ReadButton
                          noMargin
                          onClick={() => {
                            setUpdateData(val)
                            setIsReadForm(true)
                          }}
                        />
                        {HeadOfficeStatus() && (
                          <>
                            <UpdateButton
                              noMargin
                              onClick={() => {
                                setUpdateData(val)
                                setIsUpdateForm(true)
                              }}
                            />
                            <DeleteButton
                              noMargin
                              onClick={() => {
                                setDeleteData(val)
                                setIsDeleteData(true)
                              }}
                            />


                          </>
                        )}

                      </ButtonGroup>

                      <Switch
                        id={val.id_gudang}
                        checked={val.is_hidden ? false : true}
                        onChange={() => changeDataStatus(val.is_hidden, val.id_gudang)}
                      />
                    </div>
                  </TdFixed>
                  <TdFixed>{val.kode_gudang}</TdFixed>
                  <Td>{val.nama_gudang}</Td>
                  <Td>{val.nama_unit_organisasi}</Td>
                  <Td>{val.nama_karyawan}</Td>
                  <Td>{val.nama_unit_produksi}</Td>
                </Tr>
              )
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                  if (index === data.length - 1) {
                    return TableNumber(page, dataLength, index)
                  }
                })
            }
            dataNumber={data.map((res, index) => {
              if (index === 0) {
                return TableNumber(page, dataLength, index)
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value)
              setPage(1)
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch} />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          {HeadOfficeStatus() && <CreateButton onClick={() => setIsCreateForm(true)} />}
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      {isCreateForm && <TambahModal />}
      {isUpdateForm && <UbahModal />}
      {isDeleteData && <HapusModal />}
      {isReadForm && <DetailModal />}
    </CRUDLayout>
  )
}

export default Gudang
